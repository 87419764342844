<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

        <v-row>

           <v-col
          cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="arrhruserpositions_to"
              :items="dataListUserposall"
              label="Tujuan Share (Pilih User):"
              multiple
              chips
              item-text="text"
              item-value="value">
            </v-autocomplete>
      
          </v-col>

           <v-col
            cols="12"
            md="12"
          >
          <v-textarea
              v-model="postForm.subject"
              label="NOTES"
              outlined
              rows="4"
              counter
              :rules="[rules.required]"
            ></v-textarea>  
 
          </v-col>


        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Batal
            </v-btn>

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Kirim
            </v-btn>
          
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <span style="font-size:8px;">{{idLetter}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="insertShareSurat()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { insertLetterInOutShare } from '@/api/letterinout'
import { getListUsrPositionsActiveByUnit } from '@/api/hruserpositions'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  hruserpositions_from: '',
  arrhruserpositions_to: '',
  eostatus_from: '',
  eostatus_to: '',
  subject: '-',
  to_isOpen: 0,
  to_isImportant: 0,
  moduletypes: 1,
  hrpositions_to: '',
  id_letter: ''
}

export default {
  name: 'FormShare',
  components: {
  },
  props: {
    idLetter: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength(v) { 
          return (v || '').length <= 240 || `Value may not be greater than 240.`;
        }
      },
      isLoading: false,
      dialogConfirm: false,
      dataListUserposall: [],
      currrole: [],
      arrhruserpositions_to: [],
    }
  },
  created () {
    this.postForm = Object.assign({}, defaultForm)
  },
  destroyed () {
  },
  mounted () {
    const vm = this

    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
    vm.postForm = Object.assign({}, defaultForm)

    vm.parseUser()
  },
  computed: {

  },
  methods: {
    resetForm () {
      this.$refs.form.reset()
      this.postForm = Object.assign({}, defaultForm)
    },
    doCancel(){
      // console.log('back')
      this.$emit('dialogShareClose', true)
    },
    
    parseUser() {
      const vm = this
      var rData = secureStorage.getItem('userposall')

      var myUnitCode = vm.currrole.hrorganization.unit_code
      myUnitCode = myUnitCode.toString()
      var unit = myUnitCode.slice(0,2)
      
      
      if (rData !== null) {
        let temp = []
        rData.forEach(element => {

          if (element != null) {
            if (element.rolename != 'admin' && element.rolename != 'developer') {
              // console.log(element)
              if (element.prefix != null) { element.prefix = element.prefix + ' ' } else { element.prefix = '' }

              let uposid = element.id
              element['posname'] = element.prefix + element.pos_name
              element['userposname'] = element.fullname + ' (' + element['posname'] + ')' 
              element['unitcode'] = parseInt(element.unit_code)

              var strelcode = element.unit_code
              if(strelcode != null){
                var elCode = strelcode.toString()
                var elunit = elCode.slice(0,2)
                var resucode_last1 = elCode.slice(-1)

                // console.log(unit + "==" + elunit)

                let tmp = {text:  element['userposname'], value: uposid, unitcode: element['unitcode'], pos_name: element['posname'], level: resucode_last1}
                if(unit == elunit){
                  temp.push(tmp)
                }
              }
              
            }
          }
        })
        
        temp.sort((a, b) => Number(a.level) - Number(b.level))
        vm.dataListUserposall = temp
        // console.log(vm.dataListUserposall)
      }
      
    },

    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        delete vm.postForm.id
        vm.postForm.hruserpositions_from = vm.currrole.id
        var posto = []
        if(vm.arrhruserpositions_to){
          var uto = vm.arrhruserpositions_to
          uto.forEach(element => {
            if(element.value){
              posto.push(element.value)
            }else{
              posto.push(element)
            }
          });
        }
        
        // vm.postForm.arrhruserpositions_to = posto.toString()
        vm.postForm.arrhruserpositions_to = posto.join("|"); 
        vm.postForm.eostatus_from = 12
        vm.postForm.eostatus_to = 27 // Share
        vm.postForm.moduletypes = 1
        
        vm.postForm.id_letter = vm.idLetter
        vm.postForm.tgl_batas_respon = moment(vm.tglRespon).format('YYYY-MM-DD')

        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    insertShareSurat () {
      const vm = this
      vm.dialogConfirm = false

      vm.isLoading = true
      insertLetterInOutShare(vm.postForm).then(response => {
        var data = response.data
        // console.log('response:', data)

        this.$emit('dialogShareClose', true)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Share Surat BERHASIL.', timeout: 2000
        }
        vm.resetForm()
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Share Surat GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },    
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>

import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API CaseSurat */
export function getCaseSurat(listQuery, userposition, hrorganization, rolename, holding, hrorg_level, unit_code) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/casesurats/m/search?search='+ search + '&userposition=' + userposition + '&hrorganization=' + hrorganization + '&rolename=' + rolename + '&holding=' + holding + '&hrorg_level=' + hrorg_level + '&unit_code=' + unit_code + '&_limit=' + limit + '&_start=' + start + '&_sort=id%3ADESC'
  
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countCaseSurat(listQuery, userposition, hrorganization, rolename, holding, hrorg_level, unit_code) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/casesurats/m/count?search='+ search + '&userposition=' + userposition + '&hrorganization=' + hrorganization + '&rolename=' + rolename+ '&holding=' + holding + '&hrorg_level=' + hrorg_level + '&unit_code=' + unit_code
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getCaseSuratByNameKonseptor(listQuery, currrole) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/casesurats/creator/search?konseptor='+ search + '&hrorganization='+currrole.hrorganization.id+'&rolename='+currrole.rolename+'&deleted=0&_limit=' + limit + '&_start=' + start
  
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countCaseSuratByNameKonseptor(listQuery, currrole) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/casesurats/creator/count?konseptor='+ search + '&hrorganization='+currrole.hrorganization.id+'&rolename='+currrole.rolename+'&deleted=0'
  
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getCaseSuratByID(id) {
  // console.log(id)
  if (id <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/casesurats/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDataCaseBycaseGUID(guid) {
  // console.log(id)
  if (guid <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/casesurats?caseGUID=' + guid + '&_limit=1'+ '&deleted=0'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getCaseSuratSearchfromSurat(listQuery, arrsearchParam) {
  // console.log(listQuery)
  var page = listQuery.page ? listQuery.page : 15
  var limit = listQuery.limit ? listQuery.limit : 1000
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var len = arrsearchParam.length
  var strsearch = ''
  for(var i= 0;i<len;i++){
    if(i == 0){
      strsearch = 'caseGUID=' + arrsearchParam[i]
    }else{
      strsearch = strsearch + '&caseGUID=' + arrsearchParam[i]
    }
  }
  var uri = _GLOBAL.URLBACKEND + '/casesurats?'+ strsearch+ '&deleted=0'
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertCaseSurat(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/casesurats'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function deleteCaseSurat(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = {
    deleted: 1
  }
  var uri = _GLOBAL.URLBACKEND + '/casesurats/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateCaseSurat(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/casesurats/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}



/** API CaseSurat ==== */


<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
  
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

        <base-material-card 
          icon="mdi-clipboard-text"
          :title="subjecCase"
          class="px-5 py-3"
          color="green" 
        >
          <v-row>
            <v-col class="text-left">
              <v-btn small color="warning" @click="gotoCase()">
                <v-icon>mdi-arrow-left</v-icon>Kembali
              </v-btn>
            </v-col>
            <v-col>
              <v-text-field
                style="padding-top:0 !important;"
                v-model="searchKey"
                append-icon="mdi-magnify"
                label="Filter Surat"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col>
            <v-btn small color="blue" @click="doSearch()"><v-icon>mdi-magnify</v-icon></v-btn><v-btn small @click="doSearchReset()">Reset</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="red" v-on="on" @click="showAddForm()" :disabled="totalData>=50">
                      <v-icon>mdi-plus</v-icon>Buat Surat
                  </v-btn>
                </template>
                <span>Klik disini untuk Buat Surat baru, Max.50 surat dalam 1 Case Surat silakan untuk membuat Case Baru (Lanjutan).</span>
              </v-tooltip>
              
            </v-col>
          </v-row>
          
          <v-container fluid class="grey lighten-2 px-5 py-3">
            <v-timeline
              :align-top="false"
              :dense="false"
            >
              <v-timeline-item
                v-for="(item, n) in listData"
                :key="n"
                :small="true"
              >
                <span slot="opposite">{{ item.tglSurat | formatDate }}</span>
                <v-card class="elevation-2">
                  <v-card-title class="subtitle-1">

                    <v-row>
                      <v-col cols="6" class="text-left teal--text ">
                        <span class="subtitle-2 font-weight-bold " v-if="item.eolettertype > 0">{{item.eolettertypeName}}</span>
                      </v-col>
                      <v-col cols="6" class="text-right">
   
                      <v-tooltip top v-if="item.eosecuritytype == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn color="blue" v-on="on" fab x-small text @click="viewProcess(item)"  >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat Surat</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.eosecuritytype == 1 && item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19)">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.idmodel == 4 || item.idmodel == 5" fab x-small text @click="gotoEditor(item.letterGUID, item.idmodel)" v-on="on"  >
                            <v-icon class="orange--text pointer">mdi-cloud-print-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Surat Online</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.eosecuritytype == 1 && (item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19)) || currrole.rolename == 'sekretaris'">
                        <template v-slot:activator="{ on }">
                          <v-btn color="orange" v-on="on" fab x-small text @click="editProcess(item.eolettertype, item.letterGUID)"  >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Header</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.eosecuritytype == 2 && (item.hruserpositions_createdBy == currrole.id || item.hruserpositions_editedBy == currrole.id || item.hruserpositions_penandatangan == currrole.id || currrole.user && currrole.user.id == 1)">
                        <template v-slot:activator="{ on }">
                          <v-btn color="orange" v-on="on" fab x-small text @click="viewProcess(item)"  >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat Surat</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.eosecuritytype == 2 && item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19) && (item.hruserpositions_createdBy == currrole.id || item.hruserpositions_editedBy == currrole.id || item.hruserpositions_penandatangan == currrole.id)">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.idmodel == 4 || item.idmodel == 5" fab x-small text @click="gotoEditor(item.letterGUID, item.idmodel)" v-on="on"  >
                            <v-icon class="orange--text pointer">mdi-cloud-print-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Surat Online</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.eosecuritytype == 2 && item.badgeStatus.id != 6 && (isAdmin || item.badgeStatus.id != 19) && (item.hruserpositions_createdBy == currrole.id || item.hruserpositions_editedBy == currrole.id || item.hruserpositions_penandatangan == currrole.id)">
                        <template v-slot:activator="{ on }">
                          <v-btn color="orange" v-on="on" fab x-small text @click="editProcess(item.eolettertype, item.letterGUID)"  >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah Header</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.signedSurat && item.eosecuritytype == 1">
                        <template v-slot:activator="{ on }">
                          <a :href="item.signedSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="item.scanSurat && item.eosecuritytype == 1">
                        <template v-slot:activator="{ on }">
                          <a :href="item.scanSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.signedSurat && item.eosecuritytype == 2 && (item.hruserpositions_createdBy == currrole.id || item.hruserpositions_editedBy == currrole.id || item.hruserpositions_penandatangan == currrole.id)">
                        <template v-slot:activator="{ on }">
                          <a :href="item.signedSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="item.scanSurat && item.eosecuritytype == 2 && (item.hruserpositions_createdBy == currrole.id || item.hruserpositions_editedBy == currrole.id || item.hruserpositions_penandatangan == currrole.id)">
                        <template v-slot:activator="{ on }">
                          <a :href="item.scanSurat.linkfile" target="_blank" v-on="on">
                            <v-icon class="green--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>Lihat File</span>
                      </v-tooltip>

                      <v-tooltip top v-else-if="item.eosecuritytype == 2" >
                        <template v-slot:activator="{ on }">
                          <a href="#" v-on="on">
                            <v-icon class="red--text pointer">mdi-file-pdf</v-icon>
                          </a>
                        </template>
                        <span>File Surat belum diUpload / Selesai diTTD / Bersifat RAHASIA</span>
                      </v-tooltip>
                      <v-tooltip top v-if="isAdmin">
                        <template v-slot:activator="{ on }">
                          <v-btn color="red" v-on="on" fab x-small text @click="delData(item.id)"  >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus Surat</span>
                      </v-tooltip>
                    </v-col>

                     
                    
                    <v-col cols="7" class="text-left">
                      
                      <span class="subtitle-2 font-weight-bold">ID:{{ item.noid }}</span><br>
                      <span v-if="item.idmodel == 3 && item.noSurat =='-'">
                        <v-btn x-small color="info" @click="confirmGen(item.id)">Generate Nomor Surat</v-btn>
                      </span>
                      <span v-else class="blue--text pointer font-weight-bold" @click="viewProcess(item)" >
                        No: {{ item.noSurat }}
                      </span>&nbsp;
                    </v-col>
                    <v-col cols="5" class="text-right">
                        <span v-if="item.badgeStatus && item.eolettertype > 0 && item.modelsurat != 1">
                        <v-btn style="cursor: default;" :color="item.badgeStatus.color" x-small>{{item.badgeStatus.text}}</v-btn>
                        </span>
                      </v-col>
                    
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <div class="text-center grey lighten-3" style="font-size:12px;">{{ item.perihalSurat }}</div>
                    <v-divider></v-divider>
                    <div class="text-right">
                      <span class="font-italic font-weight-light" style="line-height:0;font-size:9px">Created: {{ item.createdby }}, {{ item.created_at | formatDatetime }}</span><br>
                      <span class="font-italic font-weight-light" style="line-height:0;font-size:9px">Updated: {{ item.updatedby }}, {{ item.updated_at | formatDatetime }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          
          </v-container>

        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteData()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

     

      <v-dialog v-model="gennoConfirm" persistent width="70vW">

        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>
            
            Apakah anda yakin ingin GENERATE Nomor Surat?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="gennoConfirm = false">Batal</v-btn>
            <v-btn color="red darken-1" @click="doGenerateNoSurat()">Generate Nomor</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'

  import secureStorage from '@/utils/secureStorage'
  import { getListDataSurat, getListDataSuratNew, countListDataSurat, deleteDataSurat, updateDataSurat, printSuratbyGUID } from '@/api/datasurat'
  import { getDataCaseBycaseGUID } from '@/api/casesurat'
  import { getListLetterTypesActive } from '@/api/lettertypes'
  import { generatePenomoranSrt, deleteReversingPenomoranSrt } from '@/api/penomoransrt'
  import { getLastPenandatanganApproval, updateApproval } from '@/api/letterapproval'
  
  import _ from 'lodash'

  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'ListViewCaseSurat',

    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'View Case',
          icon: 'mdi-information',
        }
      ],
      caseuid: 0,
      options: [
        { text: 'No. ID', value: 'id' },
        { text: 'No. Surat', value: 'noSurat' },
        { text: 'Perihal Surat', value: 'perihalSurat' },
        { text: 'Pengirim', value: 'suratDari' },
        { text: 'Kepada', value: 'tujuanSurat' }
      ],
      searchParam: 'perihalSurat',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          width:'100',
          class:'subtitle-1'
        },
        { text: 'Perihal Surat', value: 'perihal', align: 'left', class:'subtitle-1'},
        { text: 'No.Surat', value: 'noSurat', align: 'center', class:'subtitle-1'},
        { text: 'Tgl. Surat', value: 'tglSurat', width:'150', align: 'center', sortable: true , class:'subtitle-1'},
        { text: 'Aksi', width:'100', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      isLoading: false,
      selectedMonth: new Date().toISOString().substr(0, 7),
      menuSelMonth: false,
      delConfirm: false,
      gennoConfirm: false,
      idDel: 0,
      listData: [],
      listDataBackup: [],
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 100,
        search: ''
      },
      dataUserAll:[],
      isAdmin: false,
      dataUser: [],
      currrole: [],
      arrLetterTypes: [],
      dataCase: {},
      arrSelSurat: null,
      searchKey: '',
      subjecCase: ''
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD-MM-YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this

      vm.currrole = secureStorage.getItem('currRole')
      // console.log(vm.currrole)
      if(vm.currrole){
        if(vm.currrole.rolename == 'admin') {
          vm.isAdmin = true
        }
      }

      vm.dataUser = secureStorage.getItem('user')
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
      }

      vm.dataUserAll = secureStorage.getItem('userall')

      vm.caseuid = vm.$route.params && vm.$route.params.cuid
      vm.getdataCase()
      vm.getLetterTypes()
      
    },
    destroyed () {
    },
    mounted () {
      this.getList()
    },
    watch: {
    },
    methods: {
      gotoCase(){
        this.$router.push('/case/list/0')
      },
      goBack(){
        this.$router.go(-1)
      },
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      gotoEditor(letterGUID, model){
        if(model == 4){
          this.$router.push('/case/editord/' + letterGUID )
        }else if(model == 5){
          this.$router.push('/case/editore/' + letterGUID )
        }else {
          // console.log('model surat not detected')
        }
        
      },
      doSearchReset(){
        const vm = this
        vm.listData = vm.listDataBackup
        vm.searchKey = ''
      },
      doSearch(){
        const vm = this
        // console.log('doSearch',vm.searchKey)
        vm.listData = vm.listDataBackup

        if(vm.searchKey.length < 3) return false
        var founded = _.filter(vm.listData, function(o) { 
          var no = o.noSurat.toLowerCase()
          var per = o.perihalSurat.toLowerCase()          
          return no.includes(vm.searchKey.toLowerCase()) || per.includes(vm.searchKey.toLowerCase()) 
        })
       
        // console.log('founded',founded)
        vm.listData = founded
      },
      getdataCase(){
        const vm = this
        vm.isLoading = true
        
        getDataCaseBycaseGUID(vm.caseuid).then(response => {
          var data = response.data
          // console.log('getDataCaseBycaseGUID',data)
          
          vm.dataCase = data[0]
          vm.subjecCase = data[0].subject
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
        
        
      },
      getLetterTypes(){
        const vm = this
        vm.isLoading = true
        getListLetterTypesActive().then(response => {
          var data = response.data
          vm.arrLetterTypes = data
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
      showAddForm () {
        const vm = this 
        vm.$router.push('/case/crlettermn/'+vm.caseuid)
      },
      editProcess (kdsurat, lGUID) {
        const vm = this
        if(!lGUID || lGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
        }
        // console.log(kdsurat, lGUID)
        
        var arrSelected = vm.arrLetterTypes.find(x => x.id === kdsurat)
        // console.log(arrSelected)
        if(arrSelected){
          if(arrSelected.modelsurat){
            if(arrSelected.modelsurat.id == 1){
              vm.$router.push('/case/updlettera/' + vm.caseuid + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 2){
              vm.$router.push('/case/updletterb/' + vm.caseuid + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 3){
              vm.$router.push('/case/updletterc/' + vm.caseuid + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 4){
              vm.$router.push('/case/updletterd/' + vm.caseuid + '/' + kdsurat + '/' + lGUID)
            }else if(arrSelected.modelsurat.id == 5){
              vm.$router.push('/case/updlettere/' + vm.caseuid + '/' + kdsurat + '/' + lGUID)
            }else{
              vm.toast = {
                show: true, color:'red', text: 'Model Surat OUT OF RANGE pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
              }
            }
          }else{
            vm.toast = {
              show: true, color:'red', text: 'Model Surat NULL pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
            }
          }
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Pilih Jenis Surat terlebih dahulu', timeout: 2000
          }
        }

      },
      viewProcess (dsurat) {
        const vm = this
        if(!dsurat.scanSurat) {
          vm.toast = {
            show: true, color:'red', text: 'File Softcopy Surat (PDF) belum di Upload, silakan klik menu Ubah untuk mengupload Softcopy Surat terlebih dahulu.', timeout: 2000
          }
          return false
        }

        if(!dsurat.letterGUID || dsurat.letterGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
          return false
        }
        // console.log(dsurat)
        if(dsurat.eosecuritytype == 1 || vm.currrole.rolename == 'komite' || vm.currrole.rolename == 'VP' || vm.currrole.rolename == 'presdir' || vm.currrole.rolename == 'admin' || vm.currrole.rolename == 'sekretaris' || vm.dataUser.id == dsurat.userCreator || vm.dataUser.id == dsurat.userEditor){
          var uri = '/#/surat/v/' + dsurat.letterGUID
          // window.open(uri, '_blank')
          window.open(uri, '_self')
        }
        else if (dsurat.eosecuritytype == 2 && vm.dataUser.id == dsurat.userCreator || vm.dataUser.id == dsurat.userEditor){
        var uri = '/#/surat/v/' + dsurat.letterGUID
          // window.open(uri, '_blank')
          window.open(uri, '_self')
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Surat bersifat RAHASIA, hanya dapat dibuka oleh yang Pemilik / Konseptor!', timeout: 2000
          }
          return false
        }

      },
      getList() {
        // console.log('getList')
        var vm = this
        if(vm.currrole == null) return false

        var userposition = vm.currrole.id
        var hrorganization = vm.currrole.hrorganization.id
        var rolename = vm.currrole.rolename

        // get list of data
        vm.listLoading = true

        getListDataSuratNew(vm.listQuery, vm.caseuid, userposition, hrorganization, rolename).then(response => {
          var array = response.data
          // console.log("getListDataSuratNew", array)

          array.forEach(element => {
            
            element['unit'] = '-'
            if(element.hrorganizations > 0){
              element['unit'] = element.hrorganizationsLevel + ' ' + element.hrorganizationsStruktur 
            }
            
            element['subject'] = '-'
            if(!element.perihalSurat){
              element['subject'] = element.perihalSurat
            }
            
            element['createdby'] = '-'
            if(element.userCreatorName){
              element['createdby'] = element.userCreatorName 
            }

            element['updatedby'] = '-'
            if(element.userEditorName){
              element['updatedby'] = element.userEditorName 
            }

            if(!element.noSurat){
              element['noSurat'] = '-'
            }
            element['noid'] = '#' + element.id

            element['idmodel'] = 0
            if(element.modelsurat > 0){
              element['idmodel'] = parseInt(element.modelsurat)
            }

            element.signedSurat = null
            if(element.signedSuratURL){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.signedSuratURL, filename: element.signedSuratName}
              element.signedSurat = tmp;
            }

            element.scanSurat = null
            if(element.scanSuratURL){
              var tmp = {linkfile: _GLOBAL.URLBACKEND + element.scanSuratURL, filename: element.scanSuratName}
              element.scanSurat = tmp;
            }

            element['badgeStatus'] = { text:'', color: 'white' , id: 0}
            if(element.statusSurat == 11){
              element['badgeStatus'] = { text: element.status_name, color: 'grey' , id: element.statusSurat}
            }else if(element.statusSurat == 3){
              element['badgeStatus'] = { text: element.status_name, color: 'blue', id: element.statusSurat}
            }else if(element.statusSurat == 4 || element.statusSurat == 19){
              element['badgeStatus'] = { text: element.status_name, color: 'green', id: element.statusSurat}
            }else if(element.statusSurat == 6){
              element['badgeStatus'] = { text: element.status_name, color: 'red', id: element.statusSurat}
            } else {
              element['badgeStatus'] = { text: element.status_name, color: 'grey', id: element.statusSurat}
            }
          })

          vm.totalData = array.length
          vm.listData = array
          vm.listDataBackup = array
          vm.listLoading = false
        })
      },
      delData (id) {
        const vm = this
        vm.idDel = id
        
        var arrSelected = this.listData.find(x => x.id === vm.idDel)
        this.arrSelSurat = arrSelected
        vm.delConfirm = true
      },
      doDeleteData () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        // console.log('deleteData: ',vm.idDel)
        deleteDataSurat(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false

          vm.delReverseGenerateNomor()
          vm.getList()
          vm.toast = {
            show: true, color:'green', text: 'Data berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Data GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },
      confirmGen(idL){
        var arrSelected = this.listData.find(x => x.id === idL)
        this.arrSelSurat = arrSelected

        this.gennoConfirm = true
      },
      doGenerateNoSurat() {
        const vm = this
        this.gennoConfirm = false

        var user = secureStorage.getItem('user')
        // console.log(vm.arrSelSurat)
        if (vm.arrSelSurat != null) {
          var data = {
            moduletypes: 1,
            eolettertype: vm.arrSelSurat.eolettertype,
            hrorganizations: vm.arrSelSurat.hrorganizationPenandatangan,
            user: vm.currrole.id,
            thang: vm.arrSelSurat.thang,
            id_letter: vm.arrSelSurat.id
          }
          // console.log(data)
          data = JSON.stringify(data)
          vm.isLoading = true
          generatePenomoranSrt(data).then(responseG => {
            var respG = responseG.data
            // console.log(respG)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Generate Surat BERHASIL', timeout: 2000
            }
            vm.getList()
            vm.arrSelSurat= null
          }).catch(err => {
            console.log(err)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'red', text: 'Generate Surat GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          })
        } else {
          vm.toast = {
            show: true, color:'red', text: 'Generate Surat GAGAL - NULL Data, silakan dicoba kembali.', timeout: 2000
          }
        }
      },
      delReverseGenerateNomor() {
        const vm = this
        this.gennoConfirm = false

        var user = secureStorage.getItem('user')
        // console.log(vm.arrSelSurat)

        var getnum = vm.arrSelSurat.noSurat
        var arrone = getnum.split('/')
        var strnum = ''
        if(arrone) {
          var two = arrone[0]
          var arrtwo = two.split('-')
          var strnum = parseInt(arrtwo[1])
        }
        if (vm.arrSelSurat != null && strnum > 0) {
          var data = {
            moduletypes: 1,
            eolettertype: vm.arrSelSurat.eolettertype,
            hrorganizations: vm.arrSelSurat.hrorganizationPenandatangan,
            user: vm.currrole.id,
            thang: vm.arrSelSurat.thang,
            id_letter: vm.arrSelSurat.id,
            currNumber: strnum
          }
          // console.log(data)
          data = JSON.stringify(data)
          vm.isLoading = true
          deleteReversingPenomoranSrt(data).then(responseG => {
            var respG = responseG.data
            // console.log(respG)
            vm.isLoading = false
            vm.arrSelSurat= null
          }).catch(err => {
            console.log(err)
            vm.isLoading = false
          })
        }
      },
      
    }
  }

  
</script>
<style scoped>
.pointer {cursor: pointer;}
</style>

<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

        <base-material-card
          icon="mdi-magnify"
          :title="'Pencarian Surat: ' + skey "
          class="px-5 py-3"
          >
            <v-data-table
                :headers="headers"
                :items="listDataSearch"
                class="elevation-1 px-5 py-3"
                :loading="listLoading"
              >
              <template v-slot:item.searchlist="{ item }">
                <span v-html="item.noSurat"></span><br>
                <span>{{ item.perihalSurat }}</span><br>
                
              </template>
              <template v-slot:item.tglSurat="{ item }">
                <span>{{ item.tglSurat | formatDate}}</span>
                
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-if="item.showIcon && item.filescan" color="blue" v-on="on" fab x-small text @click="viewProcess(item)"  >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Buka Surat</span>
                  </v-tooltip>
              </template>
            
            </v-data-table>
            
        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { getletterSearch } from '@/api/datasurat'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'Search',

    data: () => ({
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      headers: [
        { text: 'NO. SURAT', value: 'searchlist', align: 'left', class:'subtitle-1' },
        { text: 'KONSEPTOR', value: 'createdby',  align: 'center', class:'subtitle-1' },
        { text: 'DIVISI', value: 'divisi',  align: 'center', class:'subtitle-1' },
        { text: 'TGL. SURAT', value: 'tglSurat',  width: '100', align: 'center', class:'subtitle-1' },
        { text: '', value: 'aksi', align: 'center', width: '100' },
      ],
      isLoading: false,
      listDataSearch: [],
      currRole: null,
      dataUser: null,
      listLoading: false,
      skey: '',
      dataUserAll: []
     
    }),

    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this

      vm.currRole = secureStorage.getItem('currRole')
      if (vm.currRole !== null) {
        // console.log(vm.currRole)
      }
      vm.dataUser = secureStorage.getItem('user')
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
      }
    },
    watch: {
      $route() {
        let searchkey = this.$route.params && this.$route.params.skey
        this.skey = decodeURIComponent(searchkey)

        this.getListSearch()
      }
    },
    destroyed () {
    },
    mounted () {
      let searchkey = this.$route.params && this.$route.params.skey
      this.skey = decodeURIComponent(searchkey)

      this.dataUserAll = secureStorage.getItem('userall')

      this.getListSearch()
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      viewProcess (dsurat) {
        const vm = this
        if(!dsurat.filescan) {
          vm.toast = {
            show: true, color:'red', text: 'File Softcopy Surat (PDF) belum di Upload, silakan klik menu Ubah untuk mengupload Softcopy Surat terlebih dahulu.', timeout: 2000
          }
          return false
        }

        if(!dsurat.letterGUID || dsurat.letterGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin', timeout: 2000
          }
          return false
        }

        if(dsurat.eosecuritytype.id == 1 || vm.currrole.rolename == 'komite' || vm.currrole.rolename == 'VP' || vm.currrole.rolename == 'presdir' ||  vm.currRole.rolename == 'admin' || vm.currRole.rolename == 'sekretaris' || vm.currRole.rolename == 'admin_sdm' || vm.dataUser.id == dsurat.userCreator.id || vm.dataUser.id == dsurat.userEditor.id){
          var uri = _GLOBAL.URLBACKEND + dsurat.filescan.url
          window.open(uri, '_blank')
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Surat bersifat RAHASIA, hanya dapat dibuka oleh yang Pemilik / Konseptor!', timeout: 2000
          }
          return false
        }

      },
      getListSearch() {
        const vm = this
        if(vm.skey.length < 3) return false
        // console.log('getListSearch',vm.skey)

        vm.listDataSearch = []
        vm.listLoading = true
        getletterSearch(vm.skey).then(response => {
          
          let array = response.data
          var filterdata = []
          if(array){
            array.forEach(element => {
              // console.log(element)

              if(element.noSurat){
                var str = element.noSurat
                str = str.toUpperCase()
                var lockey = vm.skey.toUpperCase()
                var shtml = str.replace(lockey,'<span class="yellow">' + lockey + '</span>')
                // console.log(shtml)
                element.noSurat = shtml
              }
              element['filescan'] = null
              if(element.eolettertype){
                if(element.eolettertype.modelsurat == 1){
                  element['filescan'] = element.scanSurat
                }else{
                  element['filescan'] = element.signedSurat
                }
              }

              element['createdby'] = '-'
              if(element.userCreator){
                // console.log(element.userCreator.id)
                var arrUserCreated = vm.dataUserAll.find(x => x.id === element.userCreator.id)
                element['createdby'] = arrUserCreated.fullname 
              }
              element['updatedby'] = '-'
              if(element.userEditor){
                // console.log(element.userCreator.id)
                var arrUserUpdated = vm.dataUserAll.find(x => x.id === element.userEditor.id)
                element['updatedby'] = arrUserUpdated.fullname 
              }

              element['divisi'] = '-'
              if(element.hrorganizations){
                element['divisi'] = element.hrorganizations.structure_name 
              }

              element['showIcon'] = false
              if(element.hrorganizations &&  vm.currRole.hrorganization){
                var unit = element.hrorganizations.unit_code
                unit = unit.toString()
                unit = unit.substring(0,2)
                var roleunit = vm.currRole.hrorganization.unit_code
                roleunit = roleunit.toString()
                roleunit = roleunit.substring(0,2)
                // console.log(unit,roleunit)
                if(vm.currrole.rolename == 'komite' || vm.currrole.rolename == 'VP' || vm.currrole.rolename == 'presdir' ||  vm.currRole.rolename == 'sekretaris' || vm.currRole.rolename == 'admin_sdm' || vm.currRole.rolename == 'admin' ){
                  element['showIcon'] = true
                }else if(unit == roleunit){
                  element['showIcon'] = true
                }
                filterdata.push(element)
              }

            })
            vm.listDataSearch = filterdata
            // console.log(vm.listDataSearch)
          }
          vm.listLoading = false
        })

        
      },
      
    }
  }
</script>

<template>
  <div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          
          <v-calendar
            ref="calendar"
            v-model="focus"
            :type="type"
            event-overlap-mode="stack"
            @click:more="viewDay"
            :events="fcEvents"
            :event-color="getEventColor"
            @click:event="showEvent"
            @change="getListDataSuratTugas"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogST" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
            <v-layout justify-center align-center>
              <v-card
                width="70vW"
                class="px-3 py-3 mx-lg-auto"
                outlined
              >

                <table stacked>
                  <tr>
                  <td width="100px">NO.</td>
                  <td>:</td>
                  <td><b><span v-html="selectedEvent.name"/></b></td>
                </tr>
                <tr>
                  <td width="100px">KEGIATAN</td>
                  <td>:</td>
                  <td><b><span v-html="selectedEvent.perihalSurat"/></b></td>
                </tr>
                <tr>
                  <td width="100px">LOKASI</td>
                  <td>:</td>
                  <td><b>{{ selectedEvent.st_tempat }}</b></td>
                </tr>
                <tr>
                  <td>TANGGAL</td>
                  <td>:</td>
                  <td>
                    <span style="font-weight:bold;" v-if="selectedEvent.st_dateStart == selectedEvent.st_dateEnd">{{ selectedEvent.st_dateStart }}</span>
                    <span style="font-weight:bold;" v-else>{{ selectedEvent.st_dateStart | formatDate }} s/d {{ selectedEvent.st_dateEnd | formatDate}}</span>
                  </td>
                </tr>
                </table>
                <br>
                <div class="subtitle-2 text-center">DAFTAR PELAKSANA TUGAS</div>
                <hr>
                <div v-if="dataListDaftarNama.length > 0">

                  <v-data-table
                      :headers="headers_st"
                      :items="dataListDaftarNama"
                      class="elevation-1"
                    >
                    
                    <template v-slot:item.name="{ item }">
                      <span  v-html="item.toName"/>
                    </template>
                    <template v-slot:item.unit_instansi="{ item }">
                      <span v-html="item.unit_instansi"/>
                    </template>
                    <template v-slot:item.tanggal="{ item }">
                      {{ item.tanggal }}
                    </template>
                  </v-data-table>

                </div>
                <div v-else>
                  <span class="">No record(s)</span>
                </div>
              <v-spacer></v-spacer>
              <v-row>
                <v-col cols="12">
                  <b>FILE LAMPIRAN</b>
                  <hr>
                  <div v-if="dataLampiran.length>0">
      
                        <v-data-table
                            :headers="fieldsAttach"
                            :items="dataLampiran"
                            class="elevation-1"
                          >

                          <template v-slot:item.name="{ item }">
                            <a href="#" @click="goFileLink(item.url)">{{ item.name }}</a>
                          </template>
                          <template v-slot:item.aksi="{ item }">

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <a href="#" v-on="on" @click="goFileLink(item.url)"><v-icon class="green--text">mdi-eye</v-icon></a>
                              </template>
                              <span>Buka File</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                    </div>
                    <div v-else>
                      NA
                    </div>
                </v-col>
                </v-row>

              <v-row>
                <v-col class="text-right">
                  
                  <v-btn
                    color="grey"
                    class="mr-4"
                    @click="showDialogST = false"
                  >
                    Tutup
                  </v-btn>

                  
                
                </v-col>
              </v-row>
              
              </v-card>
              

            </v-layout>
        </v-container>
    </v-dialog>
  </div>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import moment from 'moment'
import { _GLOBAL } from '@/api/server'

import { getUserPosRoles } from '@/api/positionRoles'
import { getSTFinalCurrYear } from '@/api/datasurat'
import { getDaftarSTFinalCurrYear, getDaftarPegawaiSTApproved } from '@/api/daftarpegawaiST'
import { getMasterAttachment } from '@/api/masterattachment'

export default {
  name: 'kalenderST',
  data () {
    return {
        isLoading: false,
        currrole: [],
        showDialogST: false,
        dataListDaftarNama: [],
        fcEvents: {},
        type: 'month',
        typeToLabel: {
          month: 'Month',
          week: 'Week',
          day: 'Day'
        },
        headers_st: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
            width:'100', 
            class:'subtitle-1'
          },
          { text: 'Nama', value: 'name', align: 'left', class:'subtitle-1'},
          { text: 'Unit / Instansi', value: 'unit_instansi', width:'250', align: 'center', sortable: true, class:'subtitle-1' },
          { text: 'Tanggal', value: 'tanggal', width:'250', align: 'center', sortable: true, class:'subtitle-1' },
        ],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        selectedEvent: {},
        focus: '',
        dataLampiran:[],
        fieldsAttach: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name', align: 'center', class:'subtitle-1' },
        { text: 'Uraian', value: 'notes', align: 'center', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
        
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
      return sdate
    },
    formatDatetime: function (value) {
      if (!value) return ''
      var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
      return sdate
    }
  },
  created () {
    const vm = this
    // console.log('created calendar ST')
    vm.getListDataSuratTugas()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // console.log('mount calendar ST')
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)

  },
  methods: {
    getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
          console.log(errmsg)
          vm.$alert(errmsg)
        }
      },
      getDataLampiran(idLetter) {
        const vm = this
        vm.dataLampiran = []

        // console.log('getDataLampiran', idLetter)

        getMasterAttachment(1, idLetter).then(response => {
          var rData = response.data

          var list = []
          if (rData) {
            rData.forEach(element => {
              if (element.attachment != null) {
                var temp = { id_ms: element.id, id: element.attachment.id, url: _GLOBAL.URLBACKEND + element.attachment.url, name: element.attachment.name, notes: element.notes }
                list.push(temp)
              }
            })
          }
          // console.log('getMasterAttachment', getMasterAttachment)
          vm.dataLampiran = list
        })
      },
      getListDataSuratTugas(month) {
        const vm = this
        vm.loading = true
        getSTFinalCurrYear().then(response => {
          var rData = response.data
          // console.log('getListDataSuratTugas', rData)
          if (rData !== null) {
            var i = 0
            var arrEvent = []
            rData.forEach(element => {
              i++
              if (moment(element.st_dateStart).isValid()) {
                element.st_dateStart = moment(element.st_dateStart).format('YYYY-MM-DD')
              }
              if (moment(element.st_dateEnd).isValid()) {
                element.st_dateEnd = moment(element.st_dateEnd).format('YYYY-MM-DD')
              }

              if(moment(element.st_TimeStart).isValid()){
                element.st_dateStart = element.st_dateStart
              }
              
              var allDay = false
              if(element.st_isTimeEndTick && element.st_isTimeEndTick == 1){
                allDay = true
              }

              if(element.noSurat){
                var temp = {
                  id: element.id,
                  name: element.noSurat,
                  start: element.st_dateStart,
                  end: element.st_dateEnd,
                  color: vm.colors[vm.rnd(0, vm.colors.length - 1)],
                  timed: !allDay,
                  perihalSurat: element.perihalSurat,
                  st_tempat: element.st_tempat,
                  st_dateStart: element.st_dateStart,
                  st_dateEnd: element.st_dateEnd,
                }
                // console.log('temp', temp)
                arrEvent.push(temp)
              }
              
            })
            vm.fcEvents = arrEvent
            // console.log('fcEvents', vm.fcEvents)
            
          }
          vm.loading = false
        })
    },
    
    getListDaftarNama(letterID) {
      const vm = this
      vm.isLoading = true

      // console.log('getListDaftarNama', letterID)
      getDaftarPegawaiSTApproved(letterID).then(response => {
        vm.isLoading = false
        var rData = response.data
        // console.log('getDaftarPegawaiSTApproved', rData)
        vm.showDialogST = true
        if (rData !== null) {
          rData.forEach(element => {
            if (element.hruserpositions_pegawai > 0) {
              element['toName'] = element.pegawaiName
              var prev = element.pegawaiPrefix ? element.pegawaiPrefix : ''
              element['unit_instansi'] = prev + ' ' + element.pegawaiOrg
            } else {
              element['toName'] = element.daftarNama
              element['unit_instansi'] = element.unit_instansi
            }

            var dateStart = element['dateStart']
            if (dateStart != '0000-00-00') {
              dateStart = moment(dateStart).format('DD MMM YYYY')
            }
            var dateEnd = element['dateEnd']
            if (dateEnd != '0000-00-00') {
              dateEnd = moment(dateEnd).format('DD MMM YYYY')
            }
            if (dateStart != dateEnd) {
              element['tanggal'] = dateStart + ' - ' + dateEnd
            } else {
              element['tanggal'] = dateStart
            }
          })
          vm.dataListDaftarNama = rData
          // console.log('dataListDaftarNama', vm.dataListDaftarNama)
        }
      })

      this.getDataLampiran(letterID)
    },
    showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target

          setTimeout(() => this.selectedOpen = true, 10)
        }

        if(event != null && event.id > 0){
          this.selectedEvent = event
          var letterID = event.id
          // console.log('letterID', letterID)

          this.getListDaftarNama(letterID)
          
        }

        nativeEvent.stopPropagation()
      },
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>

<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
        <v-btn color="darken-1" small @click="goBack()">&lt;&lt; Back</v-btn>
        <base-material-card
          icon="mdi-clipboard-text"
          title="List Data Surat Utilisasi eOffice "
          class="px-5 py-3"
        >

          <v-spacer></v-spacer>
          <v-data-table
              :headers="headers"
              :items="listData"
              class="elevation-1"
              :loading="listLoading"

            >

            <template v-slot:item.perihalSurat="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a   v-on="on" :href="'/#/surat/v/'+item.letterGUID" target="_blank">
                        {{item.perihalSurat}}
                  </a>
                </template>
                <span>Open Letter</span>
              </v-tooltip>
               
            </template>

            <template v-slot:item.tglSurat="{ item }">
              <div class="font-weight-light">{{ item.tglSurat | formatDate}}</div>
            </template>
            <template v-slot:item.tglTerimaSurat="{ item }">
              <div class="font-weight-light">{{ item.tglTerimaSurat | formatDatetime}}</div>
            </template>

          </v-data-table>

        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="cantOpenDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Message</v-card-title>
          <v-card-text>You don't have priviledges to open this data!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" @click="goBack()">Back</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { getDBInfoRdetail4 } from '@/api/report'

  export default {
    name: 'ListReport4',

    data: () => ({
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      headers: [
        {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'idSurat',
          },
        { text: 'Perihal', value: 'perihalSurat', sortable:true, align: 'center', class:'subtitle-1' },
        { text: 'Tgl Surat', value: 'tglSurat', width: '150px' ,sortable:true, align: 'center', class:'subtitle-1' },
        { text: 'Tgl Terima Surat', value: 'tglTerimaSurat',  width: '150px', sortable:true, align: 'center', class:'subtitle-1' },
        
      ],
      isLoading: false,
      listData: [],
      totalData: 0,
      listLoading: false,
      idUser: 0,
      month: 0,
      year: 0,
      cantOpenDialog: false
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY HH:mm')
        return sdate
      }
    },

    created () {
      const vm = this
      vm.currrole = secureStorage.getItem('currRole')
      // console.log('currRole', vm.currrole)

      var holding = vm.currrole.hrorganization['holding']
      var level = vm.currrole.hrorganization['level']

      if( level == "Main_PD" || level == "Main_VPD"){
        
      }else {
        vm.cantOpenDialog = true
      }
    },
    destroyed () {
    },
    mounted () {
      const vm = this

      vm.idUser = vm.$route.params.id
      vm.month = vm.$route.params.parM
      vm.year = vm.$route.params.parY

      // console.log(vm.idUser, vm.month, vm.year)

      if(vm.idUser > 0 && vm.month > 0 && vm.year){
        this.getList()
      }
      
    },
    watch: {
      tableOpt: {
        handler () {
          this.getList()
        },
        deep: true,
      },
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      goBack(){
        this.$router.go(-1)
      },

      async getList() {
        // console.log('getList')
        var vm = this

        getDBInfoRdetail4(vm.idUser, vm.month, vm.year).then(response => {
          var array = response.data
          if(array){
            // console.log("getDBInfoRdetail4", array)
            vm.listData = array
          }
          
          vm.listLoading = false
        })

      }
    
    }
  }
</script>

import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API Report */

export function getDBInfoReport1(companyName, holding) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/report_wms?companyName=' +companyName+ '&holding=' +holding+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDBInfoReport2(companyName, holding) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/report_was?companyName=' +companyName+ '&holding=' +holding+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoReport3(companyName, holding) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/report_fue?companyName=' +companyName+ '&holding=' +holding+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoReport4(companyName, holding) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/report_fpa?companyName=' +companyName+ '&holding=' +holding+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoReport5(companyName, holding) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/report_fps?companyName=' +companyName+ '&holding=' +holding+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoRdetail1(idUser, month, year) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/rdetail_was?idUser=' +idUser+ '&month=' +month+ '&year=' +year+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoRdetail2(idUser, month, year) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/rdetail_wms?idUser=' +idUser+ '&month=' +month+ '&year=' +year+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoRdetail3(idUser, month, year) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/rdetail_fue?idUser=' +idUser+ '&month=' +month+ '&year=' +year+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoRdetail4(idUser, month, year) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/rdetail_fpa?idUser=' +idUser+ '&month=' +month+ '&year=' +year+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

export function getDBInfoRdetail5(idUser, month, year) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/dbinfo/m/rdetail_fps?idUser=' +idUser+ '&month=' +month+ '&year=' +year+ '&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response.data)
  return response
}

/** API Report ==== */

<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
          <v-alert
                  border="left"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                <div >
                  <v-simple-table>
                    <tr>
                      <td width="100px"><span class="title-1">Penomoran</span></td>
                      <td width="20px">&nbsp;:&nbsp;</td>
                      <td><span class="subtitle-1 red--text">{{BasicInfo.numbering}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="title-1">Organisasi</span></td>
                      <td>&nbsp;:&nbsp;</td>
                      <td><span class="subtitle-1 red--text">{{BasicInfo.label}}</span></td>
                    </tr>
                  </v-simple-table>
                </div>
          </v-alert>
          <v-card
            class="pa-md-2 mx-lg-auto text-center"
            outlined
          >

            <div class="pa-md-5 text-center brown lighten-4" style="overflow: auto;">
              <vue2-org-tree 
                    :data="dataOrg" 
                    :horizontal="false"      
                    collapsable
                    @on-expand="onExpand"
                    @on-node-mouseover="onMouseover"
                    @on-node-mouseout="onMouseout"
                    class="font-weight-bold"
                />
            </div>

            <v-row>
              <v-col>

                <v-autocomplete
                  v-model="pilihHolding"
                  :items="dataListHolding"
                  label="Pilih Holding"
                  @change="(event) => updateHolding(event)"
                ></v-autocomplete>

              </v-col>
            </v-row>
          

          <v-data-table
              :headers="headers"
              :items="dataPosList"
              :items-per-page="10"
              class="mx-lg-auto pa-md-2 elevation-1 blue-grey lighten-5"
              dense
            >
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

  </v-container>
</template>

<script>
  import secureStorage from '@/utils/secureStorage'
  import moment from 'moment'
  import { getALLHROrganization } from '@/api/hrorganization'
  import { getListUsrPositionsActiveNTStaff } from '@/api/hruserpositions'

  import { _GLOBAL } from '@/api/server'
  import VueOrgTree from 'vue-org-tree'
  import Vue2OrgTree from 'vue-tree-color'

  export default {
    name: 'StrukturOrg',
    components: {
      VueOrgTree, 'vue2-org-tree': Vue2OrgTree
    },
    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      isLoading: false,
      dataOrg: {},
      dataPos: [],
      dataPosList: [],
      labelClassName: 'bg-gold',
      judge: { id_org: 2 },
      BasicSwich: false,
      dataListHolding: [],
      pilihHolding: [],
      BasicInfo: { id:null, label:null, unit_code: null },
      headers: [
        /*{
          text: 'UNIT CODE',
          align: 'start',
          class:'subtitle-1',
          sortable: false,
          value: 'unit_code',
        },*/
        {
          text: 'PENOMORAN',
          align: 'start',
          class:'subtitle-1',
          sortable: false,
          value: 'penomoran',
        },
        { text: 'JABATAN', value: 'pos_name', align: 'left', class:'subtitle-1' },
        { text: 'NAMA', value: 'fullname', align: 'left', sortable: true, class:'subtitle-1' },
      ],
      
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      //vm.dataListOrganization = secureStorage.getItem('orgall')
      // console.log('created', vm.dataListOrganization)

      getALLHROrganization().then(response => {
        vm.dataListOrganization = response.data
        // console.log('created', vm.dataListOrganization)

        vm.getList()
      }).catch(error => {
        // console.log(error)
      })

     
    },
    destroyed () {
    },
    mounted () {
      this.getUsrPos()
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },

      insertChildData(childNode, idParent, idOrg, unit_code, numbering, strLevel, strOrg) {
        // console.log("insertChildData", strOrg)
        var arr = { 'name': strOrg, 'label': strOrg, 'id_org': idOrg, 'unit_code': unit_code, 'numbering': numbering, 'children': [], photo: _GLOBAL.URLBACKEND +"/images/logo_company.png" }
        childNode.push(arr)
      },
      insertData(arrNode, idParent, idOrg, unit_code, numbering, strLevel, strOrg) {
        // console.log("arrNode.length", arrNode.length)
        if (arrNode.length > 0) {
          arrNode.forEach(element => {
            var ch = element.children
            if (ch.length > 0) {
              this.insertData(ch, idParent, idOrg, unit_code, numbering, strLevel, strOrg)
            }
            if (element.id_org === idParent) {
              this.insertChildData(ch, idParent, idOrg, unit_code, numbering, strLevel, strOrg)
            }
          })
        } else {
          
          var child = arrNode.children
          if (child && child.length > 0) {
            this.insertData(child, idParent, idOrg, unit_code, numbering, strLevel, strOrg)
          }
          if (arrNode.id_org === idParent) {
            // console.log(arrNode.id_org + "==" + idParent)
            this.insertChildData(child, idParent, idOrg, unit_code, numbering, strLevel, strOrg)
          }
        }
        return arrNode
      },

      updateHolding(event){
        var vm = this
        // console.log("event", event)
        var listorg = vm.dataPos
        // console.log("listorg", listorg)
        var newlist = []

        listorg.forEach(element => {
          if(element.holding == event){
            newlist.push(element)
          }
        })

        // console.log("newlist", newlist)
        vm.dataPosList = newlist
      },

      async getList(queryInfo) {
        var vm = this
        
        var array = vm.dataListOrganization
        if (array) {
          array.sort(function(a, b){
              return a.unit_code-b.unit_code
          })
        }

        // console.log("len data", array.length)
        var holdings =[]
        array.forEach(element => {
          // console.log(element)
          if (element.parent == null) {
            if(element.level == 'Main_PD' || element.level == 'Main_VPD') element.level = ''
            var arr = { 'name': element.structure_name, 'label': element.structure_name, 'id_org': element.id,  'unit_code': element.unit_code, 'numbering': element.numbering, 'children': [], photo: _GLOBAL.URLBACKEND +"/images/logo.png" }
            vm.dataOrg = arr
          } else {
            if(element.parent.id){
              // console.log(element)
              vm.dataOrg = vm.insertData(vm.dataOrg, element.parent.id, element.id, element.unit_code, element.numbering, element.level, element.structure_name)
            }
            
          }

          //for menu
          if (element['holding'] != "HOLDING") {
            holdings.push(element['holding'])
          }

        })

        const uniqueArr = Array.from(new Set(holdings))
        vm.dataListHolding = uniqueArr

        // console.log(vm.dataOrg)
        // Sorting
        /*if (vm.dataOrg.children) {
          vm.dataOrg.children.sort(function(a, b){
              return a.unit_code-b.unit_code
          })
        }*/
      },
      getUsrPos() {
        var vm = this

        vm.isLoading = true
        getListUsrPositionsActiveNTStaff().then(response => {
          var array = response.data
          // console.log('array',array)
          var arrTempOrg = vm.dataListOrganization
          var result = []
          array.forEach(element => {
            // console.log(element)
            if (element != null) {
              var ishead = element.hrposition.is_headunit ? element.hrposition.is_headunit : false
              if (ishead == true && arrTempOrg.length > 0) {
                arrTempOrg.forEach(alm => {
                  
                  if (element.hrposition.hrorganization === alm.id) {
                    // console.log(element)
                    var strpref = element.prefix ? element.prefix : ''
                    var jabatan = strpref + ' ' + element.hrposition.pos_name
                    var arr = { 'fullname': element.user.fullname, 'pos_name': jabatan, 'unit_code': alm.unit_code, 'penomoran': alm.numbering, 'holding': element.hrorganization.holding }
                    if(!strpref.includes('Admin') && !strpref.includes('Adm')){
                      result.push(arr)
                    }   
                  }
                })
              }
            }
            
          })
          
          vm.isLoading = false
          // Sorting
          vm.dataPos = result
          vm.dataPosList = result

          vm.dataPos.sort((a, b) => a.id.localeCompare(b.id))
          vm.dataPosList.sort((a, b) => a.id.localeCompare(b.id))
          // console.log(vm.dataPos)
        })
        .catch(function(err){
          // console.log(err)
          vm.isLoading = false
        })
      },
      onMouseout(e, data) {
        this.BasicSwich = false
      },
      onMouseover(e, data) {
        // console.log(data)
        this.BasicInfo = data;
        this.BasicSwich = true;
      },
      collapse(list) {
        var _this = this;
        list.forEach(function(child) {
            if (child.expand) {
              child.expand = false;
            }
            child.children && _this.collapse(child.children);
        });
      },
      onExpand(e,data) {
        if ("expand" in data) {
          data.expand = !data.expand;
          if (!data.expand && data.children) {
              this.collapse(data.children);
          }
        } else {
            this.$set(data, "expand", true);
        }
      },
    }
  }
</script>
<style>

.org-tree-node-label .org-tree-node-label-inner {
    padding: 10px 15px;
    text-align: center;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    background-color: orange;
}
</style>
<template>
  <v-container>
    
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto"
        outlined
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.pos_name"
              :rules="[rules.required]"
              label="Nama Posisi/ Jabatan"
            ></v-text-field>  
          </v-col>

          <v-col
          cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selHolding"
              :items="dataListHolding"
              label="Pilih Holding"
              @change="getBasedHolding()"
            ></v-autocomplete>   
      
          </v-col>


           <v-col
          cols="12"
            md="12"
          >

            <v-autocomplete
              v-model="postForm.parent"
              :items="dataListParentPos"
              label="Pilih Organisasi Atasan"
              item-text="pos_name"
              item-value="id"
              :rules="[rules.required]"
            ></v-autocomplete>
      
          </v-col>

           <v-col
          cols="12"
            md="12"
          >

            <v-autocomplete
              v-model="postForm.hrorganization"
              :items="dataListOrganization"
              label="Pilih Organisasi Jabatan ini"
              item-text="text"
              item-value="value"
              :rules="[rules.required]"
            ></v-autocomplete>
      
          </v-col>

           <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="postForm.is_headunit"
              label="Is Headunit"
            ></v-switch>
          </v-col>

          <!--
           <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="postForm.is_start_approval_ST_todirectors"
              label="Is approval ST to Directors"
            ></v-switch>
          </v-col>
        -->

          <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="postForm.isActive"
              label="isActive"
            ></v-switch>
          </v-col>

        </v-row>
        <v-row>
          <v-col>

            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click="goBack()"
            >
              Batal
            </v-btn>

            <v-btn v-if="!isEdit" :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Simpan
            </v-btn>
            <v-btn v-else :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              Ubah
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import { insertHRPositions, getHRPositionsByID, updateHRPositions, getListPositionsActiveByHolding } from '@/api/hrpositions'
import { getALLHROrganizationByHolding } from '@/api/hrorganization'

import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  pos_name: '',
  is_headunit: 0, 
  isActive: 0, 
  is_start_approval_ST_todirectors: 0,
  parent: '',
  hrorganization: '',
}

export default {
  name: 'FormPosition',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.'
      },
      uploadPercentage: 0,
      isupLoading: false,
      isLoading: false,
      dialogConfirm: false,
      dataListParentPos: [],
      dataListOrganization: [],
      dataListHolding: ["KSK", "DSU", "FAK", "FKK", "FMA", "FSK", "FSL", "MJL", "MSL"],
      selHolding: '',
    }
  },
  created () {

    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id
      this.fetchData(id)
    }
    else {
      this.postForm = Object.assign({})
    }
  },
  destroyed () {
  },
  mounted () {
    // var now = moment().format('YYYY-MM-DD')
    //this.getParentData()
    //this.getOrgData()
  },
  computed: {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    getBasedHolding(){
      const self = this
      // console.log(this.selHolding)
      this.getOrgData()
      this.getParentData()
    },
    getParentData(){
      const self = this
      getListPositionsActiveByHolding(this.selHolding).then(response => {
        var rData = response.data
        // console.log(rData)
        if (rData !== null) {
          self.dataListParentPos = rData
        }
      });  
    },
    getOrgData(){
      const self = this
      getALLHROrganizationByHolding(this.selHolding).then(response => {
        var rData = response.data
        // console.log(rData)
        if (rData !== null) {
          var array = []
          rData.forEach(element => {
            var tmp = { value: element.id, text: element.level + ' ' + element.structure_name + ' ('+ element.unit_code + ')' + ' ['+ element.holding + ']' }
            array.push(tmp)
          })
          self.dataListOrganization = array
        }
      });  
    },
    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        // console.log(vm.postForm)
        delete this.postForm.id
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    
    fetchData (id) {
      const vm = this
      vm.isLoading = true
      getHRPositionsByID(id).then(response => {
        vm.postForm = response.data
        // console.log(vm.postForm)

         if(vm.postForm.hrorganization != null){
          vm.postForm.hrorganization = vm.postForm.hrorganization.id
        }
        if(vm.postForm.parent != null){
          vm.postForm.parent = vm.postForm.parent.id
        }
        delete vm.postForm.created_at  
        delete vm.postForm.updated_at 

        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false

      // console.log('insert:',vm.postForm)
      vm.isLoading = true
      insertHRPositions(vm.postForm).then(response => {
        var data = response.data
        vm.postForm = data
        
        vm.$router.push('/pgw/hrpos')
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false

      var idb = vm.postForm.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)
      updateHRPositions(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)
        var data = response.data
        vm.postForm = data

        vm.$router.push('/pgw/hrpos')
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    
  }
}

</script>

import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API LetterApproval */
export function getlistApprovalByIdLetter(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterapprovals?datasurat=' + idsurat + '&deleted=0&_sort=id%3ADESC'
  // console.log(uri)
  
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getlistApprovalByDSignature(key) {
  
  //Akses PUBLIC
  // var uri = _GLOBAL.URLBACKEND + '/letterapprovals?DSignature=' + key + '&_sort=id%3ADESC'
  var uri = _GLOBAL.URLBACKEND + '/letterapprovals/getbyDS?key=' + key

  // console.log(uri)
  
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    data
  })
  // console.log(response)
  return response
}

export function getLastPenandatanganApproval(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterapprovals?datasurat=' + idsurat + '&deleted=0&isPenandatangan=1&_sort=id%3ADESC&_limit=1'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertMyApproval(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/letterapprovals'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateApproval(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/letterapprovals/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMTTDstaff(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/letterapprovals/f/ttdwait?parameter=' + myuserpos + '&deleted=0&_sort=id%3ADESC'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMTTDstaff(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/letterapprovals/c/ttdwait?parameter=' + myuserpos 
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API LetterApproval ==== */


import Vue from 'vue'
import Router from 'vue-router'
import secureStorage from '@/utils/secureStorage'

import Layout from '@/views/core/Layout'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: 'dashboard',
      children: [
        // Dashboard
        {
          name: 'dashboard',
          icon: 'mdi-view-dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: { name: 'Dashboard', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
        },
        {
          name: 'suratmasuk',
          icon: 'mdi-email-receive-outline',
          path: 'inbox/:idtab',
          component: () => import('@/views/suratmasuk/list'),
          meta: { name: 'Surat Masuk', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
        },
        {
          name: 'suratkeluar',
          icon: 'mdi-email-send-outline',
          path: 'outbox',
          component: () => import('@/views/suratkeluar/list'),
          meta: { name: 'Surat Keluar', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
        },
        {
          path: 'case',
          redirect: '/case/list/0',
          name: 'case',
          icon: 'mdi-timeline-plus-outline',
          component: () => import('@/views/case/index'),
          meta: { name: 'Case' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite']  },
          children: [
            {
              path: 'create',
              component: () => import('@/views/case/create'),
              name: 'Buat Case',
              meta: { name: 'Buat Case' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/case/edit'),
              name: 'Ubah Case',
              meta: { name: 'Ubah Case' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'], 
                noCache: true },
              hidden: true 
            },
            {
              path: 'list/:idtab',
              component: () => import('@/views/case/list'),
              name: 'List Case',
              meta: { name: 'List Case', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'view/:cuid',
              component: () => import('@/views/case/view'),
              name: 'View Case',
              meta: { name: 'View Case', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crlettermn/:cuid',
              component: () => import('@/views/case/createSuratMenu'),
              name: 'Pilih Jenis Surat',
              meta: { name: 'Pilih Jenis Surat' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crlettera/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/createSurat1'),
              name: 'Ins Model Surat 1',
              meta: { name: 'Buat Surat-1' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crletterb/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/createSurat2'),
              name: 'Ins Model Surat 2',
              meta: { name: 'Buat Surat-2' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crletterc/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/createSurat3'),
              name: 'Ins Model Surat 3',
              meta: { name: 'Buat Surat-3' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crletterd/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/createSurat4'),
              name: 'Ins Model Surat 4',
              meta: { name: 'Buat Surat-4' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'crlettere/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/createSurat5'),
              name: 'Ins Model Surat 5',
              meta: { name: 'Buat Surat-5' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'updlettera/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/editSurat1'),
              name: 'Upd Model Surat 1',
              meta: { name: 'Ubah Surat-1' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'updletterb/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/editSurat2'),
              name: 'Upd Model Surat 2',
              meta: { name: 'Ubah Surat-2' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'updletterc/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/editSurat3'),
              name: 'Upd Model Surat 3',
              meta: { name: 'Ubah Surat-3' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'updletterd/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/editSurat4'),
              name: 'Upd Model Surat IMEMO',
              meta: { name: 'Ubah Surat-4' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'updlettere/:cuid/:kdsurat/:lguid',
              component: () => import('@/views/case/surat/editSurat5'),
              name: 'Upd Model Surat ST',
              meta: { name: 'Ubah Surat-5' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'editord/:lguid',
              component: () => import('@/views/case/surat/editor4'),
              name: 'Editor IMEMO',
              meta: { name: 'Ubah Surat-4' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },
            {
              path: 'editore/:lguid',
              component: () => import('@/views/case/surat/editor5'),
              name: 'Editor ST',
              meta: { name: 'Ubah Surat-5' , roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
              hidden: true 
            },

            
          ],
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Report',
      icon: 'mdi-chart-bar',
      path: '/report',
      component: Layout,
      meta: { requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
      children: [
        {
          path: 'radm-1',
          component: () => import('@/views/report/tipe1'),
          name: 'Rata-rata Waktu Membuka Surat',
          meta: { name: 'Rata-rata Waktu Membuka Surat', requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager']},
          hidden: false 
        },
        {
          path: 'list1/:id/:parM/:parY',
          component: () => import('@/views/report/list1'),
          name: 'List Report Detail Surat-1',
          meta: { name: 'List Report Detail Surat-1',requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
          hidden: true
        },
        {
          path: 'radm-2',
          component: () => import('@/views/report/tipe2'),
          name: 'Rata-rata Waktu Approval Surat',
          meta: { name: 'Rata-rata Waktu Approval Surat', requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager']},
          hidden: false 
        },
        {
          path: 'list2/:id/:parM/:parY',
          component: () => import('@/views/report/list2'),
          name: 'List Report Detail Surat-2',
          meta: { name: 'List Report Detail Surat-2',requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
          hidden: true
        },
        {
          path: 'radm-3',
          component: () => import('@/views/report/tipe3'),
          name: 'Frekuensi Penggunaan eOffice',
          meta: { name: 'Frekuensi Penggunaan eOffice ', requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager']},
          hidden: false 
        },
        {
          path: 'list3/:id/:parM/:parY',
          component: () => import('@/views/report/list3'),
          name: 'List Report Detail Surat-3',
          meta: { name: 'List Report Detail Surat-3',requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
          hidden: true
        },
        {
          path: 'radm-4',
          component: () => import('@/views/report/tipe4'),
          name: 'Jumlah Pembatalan Surat (Approval Expired)',
          meta: { name: 'Jumlah Pembatalan Surat (Approval Expired)', requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager']},
          hidden: false 
        },
        {
          path: 'list4/:id/:parM/:parY',
          component: () => import('@/views/report/list4'),
          name: 'List Report Detail Surat-4',
          meta: { name: 'List Report Detail Surat-4',requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
          hidden: true
        },
        {
          path: 'radm-5',
          component: () => import('@/views/report/tipe5'),
          name: 'Jumlah Pembatalan Surat (Deleted)',
          meta: { name: 'Jumlah Pembatalan Surat (Deleted)', requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager']},
          hidden: false 
        },
        {
          path: 'list5/:id/:parM/:parY',
          component: () => import('@/views/report/list5'),
          name: 'List Report Detail Surat-5',
          meta: { name: 'List Report Detail Surat-5',requiresAuth: true, roles: ['admin', 'developer', 'GM', 'director', 'VP', 'presdir','komite', 'sekretaris','manager'] },
          hidden: true
        },
      ]
    },
    {
      name: 'sdm',
      icon: 'mdi-account-cog-outline',
      path: '/sdm',
      component: Layout,
      meta: { requiresAuth: true, roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite'] },
      children: [
        {
          path: 'org',
          redirect: '/sdm/org/list',
          name: 'sorg',
          icon: 'mdi-sitemap',
          meta: { name: 'Organisasi', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite']},
          component: () => import('@/views/sdm/index'),
          children: [
            {
              path: 'list',
              component: () => import('@/views/sdm/orgstc'),
              name: 'sorgl',
              meta: { name: 'Data Organisasi', roles: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir','komite']},
              hidden: true 
            }
          ]
        }
      ]
    },
    {
      name: 'pegawai',
      icon: 'mdi-chart-bubble',
      path: '/pgw',
      component: Layout,
      meta: { requiresAuth: true, roles:['admin'] },
      children: [
        {
          path: 'user',
          redirect: '/pgw/user/list',
          name: 'muser',
          icon: 'mdi-account-settings-outline',
          meta: { name: 'User', roles:['admin'] },
          component: () => import('@/views/pgwuser/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/pgwuser/create'),
              name: 'Buat User',
              meta: { name: 'Buat User', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/pgwuser/edit'),
              name: 'Ubah User',
              meta: { name: 'Ubah User', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/pgwuser/list'),
              name: 'List User',
              meta: { name: 'List User', roles:['admin'] },
              hidden: true 
            }
          ]
        },
        {
          path: 'userpos',
          redirect: '/pgw/userpos/list',
          name: 'userposition',
          icon: 'mdi-account-settings-outline',
          meta: { name: 'User Position', roles:['admin'] },
          component: () => import('@/views/pgwuserpos/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/pgwuserpos/create'),
              name: 'Buat User Position',
              meta: { name: 'Buat User Position', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/pgwuserpos/edit'),
              name: 'Ubah User Position',
              meta: { name: 'Ubah User Position', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/pgwuserpos/list'),
              name: 'List User Position',
              meta: { name: 'User Position', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          path: 'hrpos',
          redirect: '/pgw/hrpos/list',
          name: 'position',
          icon: 'mdi-account-settings-outline',
          meta: { name: 'Position', roles:['admin'] },
          component: () => import('@/views/pgwposition/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/pgwposition/create'),
              name: 'Buat Posisi Jabatan',
              meta: { name: 'Buat Posisi Jabatan', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/pgwposition/edit'),
              name: 'Ubah Posisi Jabatan',
              meta: { name: 'Ubah Posisi Jabatan', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/pgwposition/list'),
              name: 'List Posisi Jabatan',
              meta: { name: 'Posisi Jabatan', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          path: 'hrorg',
          redirect: '/pgw/hrorg/list',
          name: 'organization',
          icon: 'mdi-account-settings-outline',
          meta: { name: 'Organization', roles:['admin'] },
          component: () => import('@/views/pgworganization/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/pgworganization/create'),
              name: 'Buat Organisasi',
              meta: { name: 'Buat Organisasi', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/pgworganization/edit'),
              name: 'Ubah Organisasi',
              meta: { name: 'Ubah Organisasi', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/pgworganization/list'),
              name: 'List Organisasi',
              meta: { name: 'User Organisasi', roles:['admin'] },
              hidden: true
            }
          ]
        },
      ]
    },
    {
      name: 'masterdata',
      icon: 'mdi-chart-bubble',
      path: '/mdata',
      component: Layout,
      meta: { requiresAuth: true, roles:['admin'] },
      children: [
        {
          name: 'penomoransurat',
          icon: 'mdi-file-plus-outline',
          path: 'penomsrt',
          redirect: '/mdata/penomsrt/list',
          meta: { name: 'Penomoran Surat', roles:['admin'] },
          component: () => import('@/views/devlettype/index'),
          children: [
            {
              path: 'list',
              component: () => import('@/views/devpenomsrt/list'),
              name: 'List Penomoran Surat',
              meta: { name: 'Penomoran Surat', roles:['admin'] }
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devpenomsrt/edit'),
              name: 'Ubah Penomoran Surat',
              meta: { name: 'Ubah Penomoran Surat', roles:['admin'],
                noCache: true },
              hidden: true 
            },
          ]
        },
        {
          name: 'lettertype',
          icon: 'mdi-file-plus-outline',
          path: 'lettertyp',
          redirect: '/mdata/lettertyp/list',
          meta: { name: 'LetterType', roles:['admin'] },
          component: () => import('@/views/devlettype/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/devlettype/create'),
              name: 'Buat Jenis Surat',
              meta: { name: 'Buat Jenis Surat', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devlettype/edit'),
              name: 'Ubah Jenis Surat',
              meta: { name: 'Ubah Jenis Surat', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/devlettype/list'),
              name: 'List Jenis Surat',
              meta: { name: 'List Jenis Surat', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          name: 'petunjukdisposisi',
          icon: 'mdi-flag-plus-outline',
          path: 'jukdis',
          redirect: '/mdata/jukdis/list',
          meta: { name: 'Petunjuk Disposisi', roles:['admin'] },
          component: () => import('@/views/devjukdis/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/devjukdis/create'),
              name: 'Buat Petunjuk Disposisi',
              meta: { name: 'Buat Petunjuk Disposisi', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devjukdis/edit'),
              name: 'Ubah Petunjuk Disposisi',
              meta: { name: 'Ubah Petunjuk Disposisi', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/devjukdis/list'),
              name: 'List Petunjuk Disposisi',
              meta: { name: 'List Petunjuk Disposisi', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          name: 'sifatsurat',
          icon: 'mdi-beaker-plus-outline',
          path: 'letternat',
          redirect: '/mdata/letternat/list',
          meta: { name: 'Sifat Surat', roles:['admin'] },
          component: () => import('@/views/devletnat/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/devletnat/create'),
              name: 'Buat Sifat Surat',
              meta: { name: 'Buat Sifat Surat', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devletnat/edit'),
              name: 'Ubah Sifat Surat',
              meta: { name: 'Ubah Sifat Surat', roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/devletnat/list'),
              name: 'List Sifat Surat',
              meta: { name: 'List Sifat Surat', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          name: 'keamanansurat',
          icon: 'mdi-security',
          path: 'lettersec',
          redirect: '/mdata/lettersec/list',
          meta: { name: 'Keamanan Surat', roles:['admin'] },
          component: () => import('@/views/devletsec/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/devletsec/create'),
              name: 'Buat Keamanan Surat',
              meta: { name: 'Buat Keamanan Surat', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devletsec/edit'),
              name: 'Ubah Keamanan Surat',
              meta: { name: 'Ubah Keamanan Surat' , roles:['admin'],
                noCache: true },
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/devletsec/list'),
              name: 'List Keamanan Surat',
              meta: { name: 'List Keamanan Surat', roles:['admin'] },
              hidden: true
            }
          ]
        },
        {
          name: 'status',
          icon: 'mdi-message-plus-outline',
          path: 'status',
          redirect: '/mdata/status/list',
          meta: { name: 'Status', roles:['admin'] },
          component: () => import('@/views/devstatus/index'),
          children: [
            {
              path: 'create',
              component: () => import('@/views/devstatus/create'),
              name: 'Buat Status',
              meta: { name: 'Buat Status', roles:['admin'] },
              hidden: true 
            },
            {
              path: 'edit/:id',
              component: () => import('@/views/devstatus/edit'),
              name: 'Ubah Status',
              meta: { name: 'Ubah Status', noCache: true, roles:['admin'] }, 
              hidden: true 
            },
            {
              path: 'list',
              component: () => import('@/views/devstatus/list'),
              name: 'List Status',
              meta: { name: 'List Status', roles:['admin'] },
              hidden: true
            }
          ]
        }
      ]
    },
    {
      name: 'surat',
      path: '/surat',
      redirect: '/surat/v',
      component: () => import('@/views/surat/Layout'),
      hidden: true,
      children: [
        {
          path: 'v/:lguid',
          component: () => import('@/views/surat/view'),
          name: 'View Surat',
          hidden: true 
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: () => import('@/views/auth/Layout'),
      children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
          guest: true,
        },
        hidden: true
      },
      ],
    },
    {
      path: '/dsignature',
      component: () => import('@/views/dsignature/Layout'),
      hidden: true,
      children: [
        {
          path: 'v/:key',
          component: () => import('@/views/dsignature/index'),
          name: 'DSignatureView',
          hidden: true 
        },
      ]
    },
    {
      path: '/auth',
      component: () => import('@/views/auth/Layout'),
      hidden: true,
      children: [
        {
          path: 'v/:key',
          component: () => import('@/views/auth/index'),
          name: 'AuthView',
          hidden: true 
        },
      ]
    },
    {
      path: '/search',
      component: () => import('@/views/search/Layout'),
      hidden: true, 
      children: [
        {
          path: 'v/:skey',
          component: () => import('@/views/search/index'),
          name: 'Search',
          hidden: true,
          meta: {
            beforeEach: (to, from, next) => {
                store.dispatch('fetchRequest', to.params.skey);
                next();
            }
        }
        },
      ]
    },
    {
      path: '/',
      redirect: 'resetpwd',
      component: () => import('@/views/auth/Layout'),
      children: [
      {
        path: '/resetpwd/:code',
        name: 'resetpwd',
        component: () => import('@/views/auth/resetpassword'),
        meta: {
          guest: true,
        },
        hidden: true
      },
      ],
    },
    {
      path: '/',
      redirect: 'reqforgotpwd',
      component: () => import('@/views/auth/Layout'),
      children: [
      {
        path: '/reqforgotpwd',
        name: 'reqforgotpwd',
        component: () => import('@/views/auth/forgotpass'),
        meta: {
          guest: true,
        },
        hidden: true
      },
      ],
    },
    {
      path: '/logout',
      component: () => import('@/views/auth/Logout'),
      hidden: true
    },
    {
      path: '/404',
      component: () => import('@/views/errorPage/404'),
      hidden: true
    },
    {
      path: '/401',
      component: () => import('@/views/errorPage/401'),
      hidden: true
    },
  ],
})
router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log('router:',secureStorage.getItem('jwt'))
    if (secureStorage.getItem('jwt') == null) {
      // console.log('not logged')
      next({
          path: '/login',
          params: { nextUrl: to.fullPath },
      })
    } else {
      const roles = to.meta.roles
      // console.log('from.path',from.path)
      if(from.path == '/login'){ //First Login
        next()
      }else{
        const currRole = secureStorage.getItem('currRole')
        // console.log("currRole", currRole)
        if(currRole == null){
          next()
        }else{
          if(currRole.rolename){
            if (roles.includes(currRole.rolename)) {
              next()
            }else{
              next({
                path: '/401',
                  params: { nextUrl: to.fullPath },
              })
            }
          }else{
            next({
              path: '/login',
                params: { nextUrl: to.fullPath },
            })
          }
        }
        
      }
    }
  } else {
    next()
  }
})

export default router
